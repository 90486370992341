import React from "react"
import useScript from "../service/useScript"
import ivfIcon from "../../static/assets/images/center-of-excellence/ce-ivf.jpg"
import serviceIcon from "../../static/assets/images/center-of-excellence/short-stay-service.jpg"
import secureHealth from "../../static/assets/images/center-of-excellence/secure-your-health.jpg"
import careHome from "../../static/assets/images/center-of-excellence/care-home.jpg"
import Layout from "../components/layout/layout"
import Meta from "../components/addon/meta"

const CenterOfExcellence = (props) => {
const {} = props
useScript("/assets/js/centerOfExcellence.js")
return (
<Layout>
    <Meta
    files={
    {
    js: [],
    css: ['/assets/css/about-through-the-ages.css', 
    '/assets/css/accessibility.css']
    }
    }
    tags={[]}
    />
    <main className="innerpage">
        <section className="breadcrumbs mt-4">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <ul>
                            <li><a href="javascript:;">Home</a></li>
                            <li><a href="javascript:;">About Us</a></li>
                            <li><span>Special Services</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <section className="section_bg pt-first-section pb-section about-ages">
            <div className="container">
                <div className="section-head mt-4">
                    <h1>Special Services</h1>
                    <p>Our “Centres of Excellence” are dedicated to focussing on issues that affect millions. Health concerns that are oft overlooked till they no longer remain concerns. Through this focussed initiative of ours, <strong>we bring the best of international practices home</strong>. Redefining <strong>delivery of healthcare, as it has always been to what it should be</strong>. The focus is on achieving health, not curing illness.</p>
                </div>
            </div>
            <div className="container aboutContainer">
                <ul className="nav nav-tabs gallery-tabs" role="tablist">
                    <li role="presentation" className="nav-item">
                        <a className="nav-link active" data-toggle="tab" href="#ivfcentre" role="tab" aria-controls="ivfcentre" aria-selected="true">Hinduja IVF Centre</a>
                    </li>
                    <li role="presentation" className="nav-item">
                        <a className="nav-link" data-toggle="tab" href="#shortstayservice" role="tab" aria-controls="shortstayservice" aria-selected="false">Short Stay Service</a>
                    </li>
                    <li role="presentation" className="nav-item">
                        <a className="nav-link" data-toggle="tab" href="#secureyourhealth" role="tab" aria-controls="secureyourhealth" aria-selected="false">Secure Your Health</a>
                    </li>
                    <li role="presentation" className="nav-item">
                        <a className="nav-link" data-toggle="tab" href="#carehome" role="tab" aria-controls="carehome" aria-selected="false">Care@Home</a>
                    </li>
                </ul>
            </div>
            <div className="container">
                <div className="tab-content mt-3">
                    <div className="tab-pane active" id="ivfcentre" role="tabpanel" aria-labelledby="ivfcentre">
                        <div className="card">
                            <div className="row">
                                <div className="col-md-6">
                                    <figure>
                                        <img className="ls-is-cached lazyloaded" src={ivfIcon} alt="Hinduja IVF Centre" title="Hinduja IVF Centre" />
                                    </figure>
                                </div>
                                <div className="col-md-6 para_text">
                                    <div>
                                        <p>With the history of many firsts in delivering only the best to patients far and wide, comes another milestone.</p>
                                        <p><strong>Dr. Indira Hinduja</strong> now join hands with P. D. Hinduja Hospital. Hinduja IVF Centre is the fruition of our efforts with the aim providing the best of expertise and treatment options; very few can match up to.</p>
                                        <p><strong>Unrivalled expertise</strong>, standardised and <strong>transparent care delivery</strong> protocols, quality processes, hand-holding every step of the way from pre-conception to post delivery ensure <strong>you get your best chance at making your family complete</strong>.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane" id="shortstayservice" role="tabpanel" aria-labelledby="shortstayservice">
                        <div className="card">
                            <div className="row">
                                <div className="col-md-6">
                                    <figure>
                                        <img className="ls-is-cached lazyloaded" src={serviceIcon} alt="Short Stay Service" />
                                    </figure>
                                </div>
                                <div className="col-md-6 para_text">
                                    <div>
                                        <p>Getting operated doesn’t always mean having to stay long at the hospital, or having your schedule disrupted. or missing out family time.A single day suffices just as fine, At our Short Stay Service Centre. Giving you precious time to <strong>recover in the warm confines of your home</strong>. With the newest technology and some of the most able minds at the helm, our centre is one of the firsts and most sought after. From cataracts to fracture fixations, <strong>everything is managed perfectly to deliver care with compassion</strong>.</p>
                                        <a href="#" className="btn-link">Read more</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane" id="secureyourhealth" role="tabpanel" aria-labelledby="secureyourhealth">
                        <div className="card">
                            <div className="row">
                                <div className="col-md-6">
                                    <figure>
                                        <img className="ls-is-cached lazyloaded" src={secureHealth} alt="Secure Your Health" />
                                    </figure>
                                </div>
                                <div className="col-md-6 para_text">
                                    <div>
                                        <p>“Secure Your Health” is our umbrella offering driven by the emerging awareness about wellness. About preserving health to last as long as one’s life. Each of the <strong>dimensions of wellness are comprehensively covered</strong> through our Health Checkup programmes for Corporates.</p>
                                        <p>As an old adage goes “<strong>You can only go as long and as fast as you body can take you</strong>”.</p>
                                        <a href="#" className="btn-link">Read more</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane" id="carehome" role="tabpanel" aria-labelledby="carehome">
                        <div className="card">
                            <div className="row">
                                <div className="col-md-6">
                                    <figure>
                                        <img className="ls-is-cached lazyloaded" src={careHome} alt="Care Home" />
                                    </figure>
                                </div>
                                <div className="col-md-6 para_text">
                                    <div>
                                        <p>Care@Home is our endeavor which aims to support individuals obtain the best of health care services, <strong>At Any Given Moment</strong>….. to ensure convenience and reach out where you need us. With an ever growing platform of services and a unique domiciliary care network, equipped with highly trained staff, <strong>we have made getting back on your feet …simple</strong>.</p>
                                        <a href="#" className="btn-link">Read more</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</Layout>
)
}
export default CenterOfExcellence